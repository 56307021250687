.chakra-modal__close-btn {
    color: #1A202C;
}

.chakra-modal__close-btn:hover {
    color: #ffffff;
}

.chakra-modal__content {
    padding-bottom: 15px;
}

.chakra-form-control {
    margin-bottom: 15px;
}

.chakra-form-control--last {
    margin-bottom: 30px;
}

.chakra-checkbox {
    border-color: #E2E8F0;
}

#get-a-quote-modal-trigger {
    display: block;
    width: auto;
    min-width: auto;
    height: auto;
    padding: 10px 20px;
    margin: 0 0 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #625bf2;
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.7em;
    color: #fff;
}

#get-a-quote-modal-trigger:hover {
    background-color: #2e2a8f;
}

.enquiry-form input,
.enquiry-form input[type="email"],
.enquiry-form textarea {
    width: 100%;
    padding: 0 16px;
    border: 1px solid rgb(26, 32, 44);
    font-family: Roboto,sans-serif;
    font-size: 16px;
    color: #1a1a1a;
    background-color: transparent;
    border-radius: 0;
}

.enquiry-form input:hover,
.enquiry-form input[type="email"]:hover,
.enquiry-form textarea:hover {
    border-color: var(--chakra-colors-gray-300);
}

.enquiry-form input:focus-visible,
.enquiry-form input[type="email"]:focus-visible,
.enquiry-form textarea:focus-visible {
    z-index: 1;
    border-color: rgb(49, 130, 206);
    box-shadow: rgb(49, 130, 206) 0px 0px 0px 1px;
}

.enquiry-form button[type="submit"] {
    background-color: #625bf2;
    font-family: 'Roboto', sans-serif; 
    color: white;
}

.enquiry-form button[type="submit"]:hover {
    background-color: #2e2a8f;
}

.enquiry-form__alert {
    color: black;
}